window.data = function() {
  function getThemeFromLocalStorage() {
    // if user already changed the theme, use it
    if (window.localStorage.getItem('dark')) {
      return JSON.parse(window.localStorage.getItem('dark'))
    }

    // else return their preferences
    return (
      !!window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    )
  }

  function setThemeToLocalStorage(value) {
    window.localStorage.setItem('dark', value)
  }

  return {
    dark: getThemeFromLocalStorage(),
    lang: document.getElementsByTagName('html')[0].getAttribute('lang').toUpperCase(),
    toggleTheme() {
      this.dark = !this.dark
      setThemeToLocalStorage(this.dark)
    },
    setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    cartNumber: 0,
    getCartNumber(){
        if(document.querySelector('a.nav-link .fa-shopping-cart')) {
            let self = this
            fetch('/cart/total').then(function(res) {
                if(res.status==403) {
                    location.reload();
                }else if(res.status>=400) {
                    res.json().then(error => {
                        let message = '';
                        if (error.errors) {
                            if (typeof error.errors === 'object') {
                                message = Object.values(error.errors).join('\n');
                            }
                        }
                        else if (error.message) {
                            message = error.message;
                        }
                        if (!message) {
                            message = 'Unknown Error';
                        }
                        alert(message);
                    })
                }
                res.text().then(text => {
                    self.cartNumber = parseInt(text)
                });
            })
        }
    }/*,
    // Modal
    isModalOpen: false,
    trapCleanup: null,
    openModal() {
      this.isModalOpen = true
      this.trapCleanup = focusTrap(document.querySelector('#modal'))
    },
    closeModal() {
      this.isModalOpen = false
      this.trapCleanup()
    },*/
  }
}
